import { Box } from "@mui/material";

export interface ContentProps {
  "data-testid"?: string;
}

const ContentBox: React.FC<React.PropsWithChildren<ContentProps>> = (props) => (
  <Box
  sx={{ 
    height: 520, 
    width: '100%',
    backgroundColor: "white",
    padding: "24px 48px 24px 48px",
    borderRadius: 1,
    boxShadow: "none",
     }}
    data-testid={props["data-testid"]}
  >
    {props.children}
  </Box>
);

export const ContentGridBox: React.FC<React.PropsWithChildren<ContentProps>> = (props) => (
  <Box
  sx={{ 
    width: '100%',
    backgroundColor: "white",
    padding: "50px",
    borderRadius: 1,
    boxShadow: "none",
     }}
    data-testid={props["data-testid"]}
  >
    {props.children}
  </Box>
);

export default ContentBox;
